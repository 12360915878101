import {ref, reactive, toRefs, computed,watch,defineComponent, onMounted,onBeforeUnmount,onBeforeMount, getCurrentInstance,provide,nextTick} from 'vue';
import CustomerQuotationList from "../customerQuotation/CustomerQuotationList";
import QuotationCard from "../customerQuotation/QuotationCard";
const SaleBillCardHelper = defineComponent({
    name:'SaleBillCard',
    title:'销售单',
    modelType:'card',
    fullscreen: true,
    setup(){
        let {proxy}=getCurrentInstance();
        const utils=proxy.utils;
        provide('uploadOwner', proxy);
        let dataObj=reactive({
            formRef:null,
            disabled:false,
            tjDisabled:true,//对于调价单而言，只允许修改部分内容
            refMap:new Map(),
            cityData:[],
            goodsData:[],
            customerData:[],
            managerData:[],
            scheduleData:[],
            customerQuotationInfo:'',
            driverQuotationInfo:'',
            tjQuotationInfo:'',//条件需要重新选择客户报价单，这里是存储调价选择的客户报价单信息
            desc:{},
            //卡片传给dialog的初始化参数
            compParams: {
                hsDetails: false,
                modelPath: "/saleBill"
            },
            //表单
            form:{},
            signForm:{contractDate:''},
            uploadParams:{
                // formId:dataObj.form.id,
                saveType:1,
                title:'选择合同',
                autoUpload:false,
                showFileList:true,
                uploadType:'saleBillContract',
            },
            uploadDialogVisible:false,
            //表单验证规则
            rules: {
                cityId: [
                    {required: true, message: "请选择所在城市", trigger: "blur" }
                ],
                saleType: [
                    {required: true, message: "请选择销售类型", trigger: "blur" }
                ],
                customerId: [
                    {required: true, message: "请选择客户", trigger: "blur" }
                ],
                name: [
                    {required: true, message: "请输入项目名称", trigger: "blur" }
                ],
                goodsTypeId: [
                    {required: true, message: "请选择货物类型", trigger: "blur" }
                ],
                jsType: [
                    {required: true, message: "请选择结算周期", trigger: "blur" }
                ],
                invoiceType: [
                    {required: true, message: "请选择发票类型", trigger: "blur" }
                ],
                managerPersonId: [
                    {required: true, message: "请选择客户经理", trigger: "blur" }
                ],
                schedulePersonId: [
                    {required: true, message: "请选择调度专员", trigger: "blur" }
                ],
                desc:[
                    {required: true, message: "请输入项目描述", trigger: "blur" }
                ]
            },
            signFormRules:{}
        })
        onMounted(()=>{
            dataObj.desc=utils.$$tools.buildWangEditorField({fieldId:'desc'})
        })
        onBeforeUnmount(()=>{
            // 销毁编辑器（保险起见加的，其实在富文本创建的时候就清除了）
            if(dataObj.desc && dataObj.desc.destroy){
                dataObj.desc.destroy();
                dataObj.desc= null;
            }
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params) => {
                if(params && 'cityId'==params.comboId){
                    return dataObj.cityData;
                }
                if(params && 'saleType'==params.comboId){
                     return [{value:0,label:'项目'},{value:1,label:'临时'}]
                }
                if(params && 'customerId'==params.comboId){
                    return dataObj.customerData;
                }
                if(params && 'goodsTypeId'==params.comboId){
                    return dataObj.goodsData;
                }
                if(params && 'jsType'==params.comboId){
                    return [{value:0,label:'日结'},{value:1,label:'月结'}]
                }
                if(params && 'invoiceType'==params.comboId){
                    return [{value:0,label:'专票'},{value:1,label:'普票'}]
                }
                if(params && 'managerPersonId'==params.comboId){
                    return dataObj.managerData;
                }
                if(params && 'schedulePersonId'==params.comboId){
                    return dataObj.scheduleData;
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue,selectId)=>{
            if('cityId'==selectId){}
            if('saleType'==selectId){}
            if('customerId'==selectId){}
            if('goodsTypeId'==selectId){}
            if('jsType'==selectId){}
            if('invoiceType'==selectId){}
            if('managerPersonId'==selectId){}
            if('schedulePersonId'==selectId){}
        }

        const setParam=(cardEngine)=>{
            let moduleType=cardEngine.engineParams.ownerComp.listParam.queryParam.moduleType;
            return {moduleType:moduleType};
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(data,addOrLoad,cardEngine)=>{
            //货物类型可以多选，后台返回的数组以字符串的形式存在，这里需要把它转为真正的数组才能正常显示
            let goodsTypeId=JSON.parse(data.data.goodsTypeId);
            dataObj.form.goodsTypeId=goodsTypeId;

            if(data.data.desc){
                dataObj.desc.txt.html(data.data.desc) //重新设置编辑器内容
            }
            if(data.data.customerQuotationCode && data.data.customerQuotationName){//编辑的时候，后台会传入customerQuotationCode、customerQuotationName。新增的时候没有
                dataObj.customerQuotationInfo=data.data.customerQuotationCode+'  '+data.data.customerQuotationName;
            }
            if(data.data.driverQuotationCode && data.data.driverQuotationName){//注意：编辑的时候，都可能没有driverQuotationCode、driverQuotationName。所以必须这样判断
                dataObj.driverQuotationInfo=data.data.driverQuotationCode+'  '+data.data.driverQuotationName;
            }
            if('/load'==addOrLoad){
                if(data.data.billType==1){
                    dataObj.disabled=true;
                    //当前状态是调价后的单子，那么只允许修改页面部分内容，这个字段配合disabled一起用，这样就可以实现页面一部分可以修改，一部分不可以修改
                    if([6,7].indexOf(data.data.status)>-1){
                        dataObj.tjDisabled=false;
                    }
                }
                if([3,4,5].indexOf(data.data.status)>-1){//当前状态是否停用单据
                    dataObj.disabled=true;
                }
                if(dataObj.disabled)dataObj.desc.disable();
            }
            //下拉框赋值，一起查询出来的，免得一个个发请求
            dataObj.cityData = utils.$$lghdUtils.dealSelectData(data.cityData);
            dataObj.goodsData = utils.$$lghdUtils.dealSelectData(data.goodsData);
            dataObj.customerData = utils.$$lghdUtils.dealSelectData(data.customerData);
            dataObj.managerData = utils.$$lghdUtils.dealSelectData(data.personData);
            dataObj.scheduleData = utils.$$lghdUtils.dealSelectData(data.personData);
            nextTick(()=>{
                //如果是已签约或者调价的单子，在打开的时候就有合同附件了，这个时候可以呈现到单据的尾部
                if(data.data.contractList && data.data.contractList.length>0){
                    utils.$$lghdUtils.loadUploadFiles({dataObj:dataObj,proxy:proxy,uploadType:'saleBillContract',files:data.data.contractList,disabled:true});
                    dataObj.form.contractList=data.data.contractList;
                    dataObj.signForm.beganDate=data.data.beganDate;
                    dataObj.signForm.endDate=data.data.endDate;
                }
            })
        }
        const beforeValidate=(contentName,cardEngine)=>{
            //取出富文本编辑器中的内容赋值给表单指定项
            let desc=dataObj.desc.txt.html();
            dataObj.form.desc=desc;
            if(utils.$$str.isWangEditEmpty(desc)){
                utils.$$tools.warning({message:'项目描述不能为空'})
                return false;
            }
            return true;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            if(utils.$$str.isEmpty(dataObj.form.customerQuotationId)){
                utils.$$tools.warning({message:'请选择客户报价单'})
                return false;
            }
            return true;
        }
        //选择客户报价单
        const chooseCustomerQuotation=async ()=>{
            await utils.$$lghdUtils.buildCommonDialog({from:'saleBill',saleBillId:dataObj.form.id,title: "选择客户报价单",dialogDiv:"CustomerQuotationList",modelComp: CustomerQuotationList,proxy:proxy});
        }
        //选择客户报价单(客户调价单)确认事件、审核确认事件
        const sureHandler=async (modelInst)=>{
            //选择客户报价单或者调价单的时候，打开的都是列表选择，所以会有pageList，但是审核确认的弹出框里面们没有这个属性.
            //所以可以用他来区分是那种情况的弹出框确认
            if(modelInst.pageList){
                //通过选择的报价单为销售单上相应的属性赋值
                let selectRows = modelInst.pageListRef.getTbInst().getSelection();
                if(selectRows.length>0){
                    let customerQuotationId=selectRows[0]['F_ID'];
                    let customerQuotationCode=selectRows[0]['F_CODE'];
                    let customerQuotationName=selectRows[0]['F_NAME'];

                    if(dataObj.form.saleBillId){//点击调价的时候，会自动为form表单添加一个属性saleBillId，该属性为即将调价的销售单id
                        dataObj.form.tjCustomerQuotationId=customerQuotationId;
                        dataObj.tjQuotationInfo=customerQuotationCode+'  '+customerQuotationName;
                    }else{
                        dataObj.form.customerQuotationId=customerQuotationId;
                        dataObj.customerQuotationInfo=customerQuotationCode+'  '+customerQuotationName;
                    }
                }
                return true;
            }else{//审核确认事件
                let res=await utils.$$lghdUtils.claimDialogSure({modelInst:modelInst,proxy:proxy,url: dataObj.compParams.modelPath + "/custom",id:dataObj.form.id});
                return res;
            }
        }
        //查看选择的客户/承运商报价单
        const quotationDetail=async (moduleType)=>{
            let quotationId=dataObj.form.customerQuotationId;
            if('driver'==moduleType)quotationId=dataObj.form.driverQuotationId;
            await utils.$$lghdUtils.buildCommonDialog({addOrEdit:'edit',id:quotationId,from:'saleBill',moduleType:moduleType,
                                        showFooterBtn:false,title: "查看客户报价单",dialogDiv:"QuotationCard",modelComp: QuotationCard,proxy:proxy})
        }
        const startHandler=async()=>{
            utils.$$lghdUtils.startHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const stopHandler=async()=>{
            utils.$$lghdUtils.stopHandler({id:dataObj.form.id,proxy:proxy,url:dataObj.compParams.modelPath + "/custom"});
        }
        const channelClaimHandler=async ()=>{
            await utils.$$lghdUtils.buildClaimDialog({proxy:proxy});
        }
        const signHandle=()=>{
            dataObj.uploadDialogVisible=true;
        }
        //-----------------------------上传--------------------------------
        const buildUploadParams=async (formData,params,uploadInst)=>{
            if((uploadInst.uploadParams.saveType)==0){
                formData.append('contractDate',dataObj.signForm.contractDate);
                if(dataObj.form.saleBillId){//如果是调价上传
                    formData.append('tjCustomerQuotationId',dataObj.form.tjCustomerQuotationId);
                    formData.append('contractDate',dataObj.signForm.contractDate);
                    formData.append('id',dataObj.form.id);
                    formData.append('operateType','tj');
                }else{
                    formData.append('contractDate',dataObj.signForm.contractDate);
                    formData.append('belongMaxId',dataObj.form.id);
                    formData.append('operateType','qy');
                }
            }else{
                formData.contractDate=dataObj.signForm.contractDate;
                if(dataObj.form.saleBillId){//如果是调价上传
                    formData.tjCustomerQuotationId=dataObj.form.tjCustomerQuotationId;
                    formData.contractDate=dataObj.signForm.contractDate;
                    formData.id=form.id;
                    formData.operateType='tj';
                }else{
                    formData.contractDate=dataObj.signForm.contractDate;
                    formData.belongMaxId=dataObj.form.id;
                    formData.operateType='qy';
                }
            }
        }
        //上传成功之后，需要把本次上传的图片文件追加到上传控件文件数组中，
        const afterResult=async (res,uploadInst)=>{
            await proxy.engine.doAddOrLoad(dataObj.form.id);
            proxy.engine.engineParams.ownerComp.queryHandler();
        }
        //提交上传
        const signSubmit=async ()=>{
            let contractDate=dataObj.signForm.contractDate;
            //调价弹出框打开后会选择客户报价单，回填之后会给dataObj.form.tjCustomerQuotationId赋值
            let tjCustomerQuotationId=dataObj.form.tjCustomerQuotationId;
            let today=utils.$$str.formatDate(new Date());
            if(!contractDate){
               utils.$$tools.warning({message:'请选择合同日期'});
            }else if(today>dataObj.signForm.contractDate[1]){
               utils.$$tools.warning({message:'合同结束日期不能小于今天日期'});
            }else if(dataObj.form.saleBillId && !tjCustomerQuotationId){
               utils.$$tools.warning({message:'请选择客户报价单'});
            }else{
               // console.log(dataObj.refMap.get(dataObj.uploadParams.uploadType).curUploadNum)
               if(dataObj.refMap.get(dataObj.uploadParams.uploadType).curUploadNum==0){
                   proxy.$message({showClose: true, message: '请选择要上传的合同', type: 'info'});
               }else{
                   dataObj.refMap.get(dataObj.uploadParams.uploadType).uploadComp.submit()
                   // proxy.$message({showClose: true, message: '操作成功', type: 'success'});
                   dataObj.uploadDialogVisible=false;
                   // await proxy.engine.doAddOrLoad(dataObj.form.id);
                   // proxy.engine.engineParams.ownerComp.queryHandler();
               }
            }
        }
        //文件上传成功
        const uploadOnSuccess=async (response, file, fileList,uploadInst)=>{
            // proxy.$message({showClose: true, message: '操作成功', type: 'success'});
            // dataObj.uploadDialogVisible=false;
            await proxy.engine.doAddOrLoad(dataObj.form.id);
            proxy.engine.engineParams.ownerComp.queryHandler();
        }
        //下载附件
        const handlePreview=(file)=>{
            utils.$$tools.configBox({
                msgContent:'即将下载该附件，是否继续？',
                fn: ()=>{
                    let params={id:file.id,fileName:file.name};
                    utils.$$downLoad({url:'downLoadFile',params:params})
                }
            });
        }
        const beforeRemove=(file, fileList)=>{
            if(file.id){//如果是查看销售单详情，那么file肯定有id，这个时候不能删除附件，如果只是签约上传得临时附件，是没有id得，就可以删除附件
                return false;
            }
            return true;
        }

        const tjHandler=async ()=>{
            dataObj.uploadDialogVisible=true;
            dataObj.tjQuotationInfo='';
            dataObj.form.saleBillId=dataObj.form.id;
        }
        return{
            ...toRefs(dataObj),comboSelect,setParam,selectOnChange,beforeOpen,beforeValidate,beforeSaveHandler,chooseCustomerQuotation,sureHandler,quotationDetail,
            startHandler,stopHandler,channelClaimHandler,signHandle,buildUploadParams,signSubmit,handlePreview,beforeRemove,tjHandler,uploadOnSuccess,afterResult
        }
    }
});
export default SaleBillCardHelper;